<template>
  <v-container class="pa-0">
    <v-row class="no-gutters elevation-0">
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
        <v-responsive class="custom-chat-height-wrapper" v-if="activeChat">
          <v-card class="d-flex flex-column fill-height">
            <v-card-title class="chat-header">
              <v-btn
                solo
                color="#2a9031"
                class="elevation-0 pa-0"
                min-width="40"
                @click="$router.push('/dashboard/message-center')"
              >
                <v-icon color="white">mdi-arrow-left</v-icon>
              </v-btn>
              <div>
                <span>{{ (postThread.post) ? postThread.post.message : '' }}</span>
              </div>
            </v-card-title>
            <v-card-text
              id="chatWindow"
              class="pt-4 flex-grow-1 overflow-y-auto custom-chat-height"
            >
              <template v-for="(message, i) in messages">
                <div
                  :key="i"
                  :class="
                    message.sender.id == user.id
                      ? 'writer-user chat-message-group'
                      : 'chat-message-group'
                  "
                >
                  <!-- <div class="chat-thumb">

                        </div>  -->
                  <div class="chat-messages">
                    <span class="avator">
                      {{ message.sender.name }}
                    </span>
                    <div class="message">{{ message.body }}</div>
                    <div class="from">{{ moment(message.sent_at).format('LT') }}</div>
                  </div>
                </div>
              </template>
            </v-card-text>
            <ApiErrorMessage :message="apiErrorMessage" />
            <v-card-text class="flex-shrink-1 br-top d-flex align-center">
              <v-text-field
                v-model="message"
                :label="$t('messages.typeSomething')"
                type="text"
                no-details
                solo
                hide-details
              />
              <v-btn
                :disabled="!message"
                medium
                light
                @click="submitPostReply"
                min-width="10"
                min-height="50"
                class="green darken-4 green-gradient white--text ml-4"
                elevation="0"
              >
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-responsive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage")
  },
  data: () => ({
    activeChat: 1,
    message: null,
    apiErrorMessage: null
  }),

  computed: {
    ...mapGetters({
      postThread: "postThread/getPostThread",
      user: "user/getUser",
      messages: "messages/getMessages",
    }),
  },

  async mounted() {
    this.$store.commit("setLoader", true);
    try {
      await this.getPostThread(this.$route.params.postThreadId);
      await this.getMessages(this.$route.params.postThreadId);
    } catch ({message}) {
      this.apiErrorMessage = message;
    } finally {
      this.$store.commit("setLoader", false);
    }
    this.setScrollBottom();
  },

  methods: {
    ...mapActions({
      getPostThread: "postThread/getPostThread",
      getMessages: "messages/getMessages",
      createMessages: "messages/createMessages",
    }),

    async submitPostReply() {
      this.$store.commit("setLoader", true);
      try {
        const recipientId = this.postThread.owner.id == this.user.id ? this.postThread.recipient.id : this.postThread.owner.id;
        await this.createMessages({
          post_id: this.postThread.post.id,
          recipient_id: recipientId,
          body: this.message,
        });
        this.message = null;
        await this.getMessages(this.$route.params.postThreadId);
        this.setScrollBottom();
      } catch ({message}) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    setScrollBottom() {
      const objDiv = document.getElementById("chatWindow");
      objDiv.scrollTop = objDiv.scrollHeight;
    },
  }
};
</script>
